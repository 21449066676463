import { ComponentProps } from "react"
import ReactMarkdown from "react-markdown"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash"
import css from "react-syntax-highlighter/dist/cjs/languages/prism/css"
import go from "react-syntax-highlighter/dist/cjs/languages/prism/go"
import json from "react-syntax-highlighter/dist/cjs/languages/prism/json"
import markdown from "react-syntax-highlighter/dist/cjs/languages/prism/markdown"
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx"
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript"
import yaml from "react-syntax-highlighter/dist/cjs/languages/prism/yaml"
import CodeBox, {
  Languages,
} from "../../../../components/freestanding/codebox/codebox"

SyntaxHighlighter.registerLanguage("tsx", tsx)
SyntaxHighlighter.registerLanguage("typescript", typescript)
SyntaxHighlighter.registerLanguage("css", css)
SyntaxHighlighter.registerLanguage("bash", bash)
SyntaxHighlighter.registerLanguage("markdown", markdown)
SyntaxHighlighter.registerLanguage("json", json)
SyntaxHighlighter.registerLanguage("go", go)
SyntaxHighlighter.registerLanguage("yaml", yaml)

export interface PropTypes extends ComponentProps<typeof ReactMarkdown> {
  allowedElements?: string[]
}

const Markdown = ({ children, allowedElements }: PropTypes) => {
  return (
    <ReactMarkdown
      allowedElements={allowedElements}
      unwrapDisallowed
      components={{
        h1: ({ node, ...props }) => (
          <h2
            className="dark:text-cyan-50 mt-12 text-4xl font-bold text-indigo-900 first-of-type:mb-4 lg:text-5xl xl:text-6xl"
            {...props}
          />
        ),
        h2: ({ node, ...props }) => (
          <h2
            className="dark:text-cyan-50 mt-12 text-3xl font-bold text-indigo-900 lg:text-4xl xl:text-5xl"
            {...props}
          />
        ),
        h3: ({ node, ...props }) => (
          <h3
            className="dark:text-cyan-50 mt-12 text-2xl font-bold text-indigo-900 lg:text-3xl xl:text-4xl"
            {...props}
          />
        ),
        h4: ({ node, ...props }) => (
          <h4
            className="dark:text-cyan-50 mt-12 text-xl font-bold text-indigo-900 lg:text-2xl xl:text-3xl"
            {...props}
          />
        ),
        h5: ({ node, ...props }) => (
          <h5
            className="mt-12 text-lg font-bold text-gray-600 lg:text-xl xl:text-2xl"
            {...props}
          />
        ),
        h6: ({ node, ...props }) => (
          <h6
            className="mt-12 text-base font-bold text-gray-600 lg:text-lg xl:text-xl"
            {...props}
          />
        ),
        p: ({ node, ...props }) => (
          <p
            className="dark:text-gray-300 mt-4 text-base leading-loose text-gray-600 first-of-type:mt-0 lg:text-lg"
            {...props}
          />
        ),
        ul: ({ node, ...props }) => (
          <ul className="ml-2 list-inside list-disc pt-4" {...props} />
        ),
        li: ({ node, ...props }) => (
          <li
            className="dark:text-gray-300 list-item text-base leading-relaxed text-gray-600 lg:text-lg"
            {...props}
          />
        ),
        img: ({ node, ...props }) => (
          <figure>
            <img className="w-full" src={props.src} alt={props.alt} />
            <figcaption className="dark:text-gray-300 py-2 text-xs font-normal text-gray-600">
              {props.title}
            </figcaption>
          </figure>
        ),
        pre: ({ node, ...props }) => (
          <pre
            className="mb-4 mt-6 overflow-hidden whitespace-pre-wrap rounded-lg"
            {...props}
          />
        ),
        code: ({ node, ...props }) => {
          const match = /language-(\w+)/.exec(props.className || "")

          // TODO: quick fix! needs refactoring
          return match ? (
            <CodeBox
              tabs={[
                {
                  filename: "",
                  code: props.children[0] as string,
                  language: Languages.JSON,
                },
              ]}
            />
          ) : (
            <code {...props} />
          )
        },
        a: ({ node, ...props }) => (
          <a className="font-medium not-italic text-indigo-600" {...props} />
        ),
        em: ({ node, ...props }) => {
          return (
            <em className="font-medium not-italic text-indigo-600" {...props} />
          )
        },
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
