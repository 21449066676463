// extracted by mini-css-extract-plugin
export var active = "codebox-module--active--c74c5";
export var box = "codebox-module--box--30984";
export var content = "codebox-module--content--4dc8b";
export var editorHeader = "codebox-module--editor-header--1f033";
export var primary = "codebox-module--primary--f9942";
export var selected = "codebox-module--selected--7ed89";
export var tab = "codebox-module--tab--3c482";
export var tabs = "codebox-module--tabs--01969";
export var windowAction = "codebox-module--window-action--a32fa";
export var windowActions = "codebox-module--window-actions--9d9b6";